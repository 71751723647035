import { RightOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Progress, Spin } from "antd";
import { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";
import pointEventAPI from "src/api/pointEventAPI";
import pointEventIWSAPI from "src/api/pointEventIWSAPI";
import { DEBT_YEAR, SALES_OVER_LAST_YEAR } from "src/constants/pointEvent";
import useConfig from "src/hooks/useConfig";
const notHaveDetailList = [DEBT_YEAR, SALES_OVER_LAST_YEAR];

const PointEvent = () => {
  const { customerId, iwsApp } = useConfig();
  const currentPointAPI = iwsApp ? pointEventIWSAPI : pointEventAPI;

  const { data: pointEventList, isLoading: pointEventListLoading } = useQuery(
    ["point-event-list"],
    () => currentPointAPI.getAll(undefined, customerId)
  );

  const { data: rankList } = useQuery(
    ["rank-list"],
    () => currentPointAPI.getRankAll(),
    {
      select: data =>
        data?.data?.sort((a, b) => Number(a.point) - Number(b.point)),
    }
  );

  const nextRank = useMemo(() => {
    const currentRank = rankList?.findIndex(
      rank => rank?.id === pointEventList?.rank_info?.id
    );
    if (currentRank === -1) return;

    const nextRank = rankList?.[currentRank + 1];
    return nextRank;
  }, [pointEventList, rankList]);

  const calcPoint = () => {
    const nextRankPoint = Number(nextRank?.point);
    const currentRankPoint = Number(pointEventList?.point);

    const pointToGetNextRank = nextRankPoint - currentRankPoint;
    const progressToGetNextRank = (currentRankPoint - nextRankPoint) * 100;
    return [
      Math.ceil(pointToGetNextRank) || 0,
      Math.ceil(progressToGetNextRank) || 0,
    ];
  };

  const [pointToGetNextRank, progressToGetNextRank] = calcPoint();

  if (pointEventListLoading) {
    return (
      <div className="main-loading">
        <Spin />
      </div>
    );
  }

  return (
    <div className="point-event">
      <div className="main-item">
        <div className="main-item__name">
          {pointEventList?.customer_info?.name}
        </div>
        <div className="main-item__rank">
          Thành viên {pointEventList?.rank_info?.name}
        </div>
        <div>Tổng điểm đang có: {pointEventList?.point}</div>
        {Boolean(nextRank) && (
          <Fragment>
            <div>
              <Progress
                percent={progressToGetNextRank}
                showInfo={false}
                strokeColor="#f0ca68"
              />
            </div>
            <div className="main-item__footer">
              Điểm cần để lên hạng {nextRank?.name}: {pointToGetNextRank}
            </div>
          </Fragment>
        )}
      </div>

      {pointEventList?.point_details?.map(item => {
        if (notHaveDetailList.includes(item.point_code)) {
          return (
            <div className="sub-item">
              <div>
                <div>{item?.point_info?.name}</div>
                <div className="sub-item__desc">
                  <span>{item?.point} điểm</span> Vemedim Rewards
                </div>
              </div>
            </div>
          );
        }
        return (
          <Link
            to={`/point-events/${item.point_code}`}
            style={{ display: "block" }}
            key={item.id}
          >
            <div className="sub-item">
              <div>
                <div>{item?.point_info?.name}</div>
                <div className="sub-item__desc">
                  <span>{item?.point} điểm</span> Vemedim Rewards
                </div>
              </div>
              <Button
                type="text"
                shape="circle"
                icon={<RightOutlined className="sub-item__icon" />}
              />
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default PointEvent;
