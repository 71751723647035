import "antd/dist/antd.min.css";
import { Outlet } from "react-router-dom";
import "./App.scss";
import Header from "./components/Header";
import useConfig from "./hooks/useConfig";

function App() {
  const { iwsApp } = useConfig();

  return (
    <div className={`app custom-antd ${iwsApp ? "iws" : ""}`}>
      <Header />
      <Outlet />
    </div>
  );
}

export default App;
