import axiosClient from "./axiosClient";

const pointEventAPI = {
  getAll(params) {
    const url = "/v1/dealer/member-point";
    return axiosClient.get(url, { params });
  },

  getRankAll(params) {
    const url = "/v1/dealer/rank-info-detail";
    return axiosClient.get(url, { params });
  },

  getTabAll(params) {
    const url = "/v1/dealer/events-point";
    return axiosClient.get(url, { params });
  },

  getTabContent(params, pointCode) {
    const url = `/v1/dealer/members-point-detail/${pointCode
      .toLowerCase()
      .replace(new RegExp("_", "g"), "-")}`;
    return axiosClient.get(url, { params });
  },
};

export default pointEventAPI;
