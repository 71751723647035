import axiosClient from "./axiosClient";

const pointEventIWSAPI = {
  getAll(params, customerId) {
    const url = `/v1/dealer/members-point/${customerId}`;
    return axiosClient.get(url, { params });
  },

  getRankAll(params) {
    const url = "/v1/dealer/rank-info-detail";
    return axiosClient.get(url, { params });
  },

  getTabAll(params) {
    const url = "/v1/dealer/events-point";
    return axiosClient.get(url, { params }); // need customer_id params
  },

  getTabContent(params, pointCode, customerId) {
    const url = `/v1/dealer/members-point-detail/${pointCode
      .toLowerCase()
      .replace(new RegExp("_", "g"), "-")}/${customerId}`;
    return axiosClient.get(url, { params });
  },
};

export default pointEventIWSAPI;
