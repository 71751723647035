import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import pointEventAPI from "src/api/pointEventAPI";
import QuantityProduct from "./QuantityProduct";
import SalesProductGroup from "./SalesProductGroup";
import DebtMonth from "./DebtMonth";
import {
  DEBT_MONTH,
  QUANTITY_PRODUCT,
  SALES_PRODUCT_GROUP,
} from "src/constants/pointEvent";
import useConfig from "src/hooks/useConfig";
import pointEventIWSAPI from "src/api/pointEventIWSAPI";
import { Spin } from "antd";

const PointEvent = () => {
  let { pointCode } = useParams();
  const { customerId, iwsApp } = useConfig();
  const currentPointAPI = iwsApp ? pointEventIWSAPI : pointEventAPI;
  const [currentEventId, setCurrentEventId] = useState(null);

  const { data: tabList } = useQuery(
    ["tab-list", pointCode],
    () =>
      currentPointAPI.getTabAll({
        point_code: pointCode,
        customer_id: customerId,
      }),
    {
      enabled: [QUANTITY_PRODUCT, SALES_PRODUCT_GROUP].includes(pointCode),
      select: data => data?.data,
    }
  );

  const { data: pointEventList, isLoading: pointEventListLoading } = useQuery(
    ["point-event-list"],
    () => currentPointAPI.getAll(undefined, customerId)
  );

  useEffect(() => {
    if (tabList?.[0]) {
      setCurrentEventId(tabList?.[0]?.id);
    }
  }, [tabList]);

  const currentPointEvent = pointEventList?.point_details?.find(
    pointEvent => pointEvent.point_code === pointCode
  );

  const { data: tabContent, isLoading: tabContentLoading } = useQuery(
    ["tab-content", currentEventId],
    () =>
      currentPointAPI.getTabContent(
        { event_id: currentEventId },
        pointCode,
        customerId
      ),
    {
      enabled: Boolean(currentEventId) || pointCode === "DEBT_MONTH",
      select: data => data?.data,
    }
  );

  const handleTabChange = key => {
    setCurrentEventId(Number(key));
  };

  if (pointEventListLoading) {
    return (
      <div className="main-loading">
        <Spin />
      </div>
    );
  }

  return (
    <div className="point-event-detail">
      <div className="heading-box">
        <div>{currentPointEvent?.point_info?.name}</div>
        <div>
          <span>{currentPointEvent?.point} điểm</span> Vemedim Rewards
        </div>
      </div>

      {pointCode === QUANTITY_PRODUCT && (
        <QuantityProduct
          tabList={tabList}
          currentTab={tabList?.find(tab => tab.id === currentEventId)}
          tabContent={tabContent}
          tabContentLoading={tabContentLoading}
          onTabChange={handleTabChange}
        />
      )}

      {pointCode === SALES_PRODUCT_GROUP && (
        <SalesProductGroup
          tabList={tabList}
          currentTab={tabList?.find(tab => tab.id === currentEventId)}
          tabContent={tabContent}
          tabContentLoading={tabContentLoading}
          onTabChange={handleTabChange}
        />
      )}

      {pointCode === DEBT_MONTH && (
        <DebtMonth
          tabList={tabList}
          currentTab={tabList?.find(tab => tab.id === currentEventId)}
          tabContent={tabContent}
          tabContentLoading={tabContentLoading}
          onTabChange={handleTabChange}
        />
      )}
    </div>
  );
};

export default PointEvent;
