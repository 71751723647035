import { Table } from "antd";
import PropTypes from "prop-types";

const currentMonth = new Date().getMonth() + 1;
function DebtMonth({ tabContent, tabContentLoading }) {
  const columns = [
    {
      title: "Tháng",
      key: "month",
      render: value => value?.month?.slice(-2),
    },
    {
      title: "Mục tiêu điểm tích lũy",
      key: "target_point",
      dataIndex: "target_point",
      align: "center",
      width: 150,
    },
    {
      title: "Điểm tích lũy đạt được",
      key: "current_point",
      dataIndex: "current_point",
      align: "center",
      width: 150,
    },
  ];

  return (
    <Table
      rowClassName={record =>
        Number(record?.month?.slice(-2)) === currentMonth
          ? "highlight-row-table"
          : ""
      }
      columns={columns}
      dataSource={tabContent}
      size="middle"
      pagination={false}
      loading={tabContentLoading}
    />
  );
}

DebtMonth.propTypes = {
  tabList: PropTypes.array,
  tabContent: PropTypes.array,
  currentTab: PropTypes.object,
  onTabChange: PropTypes.func,
  tabContentLoading: PropTypes.bool,
};

export default DebtMonth;
