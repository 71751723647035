import { TOKEN_STORAGE_KEY } from "src/constants/common";

export const formatDateToString = date => {
  const newDate = new Date(date);
  return (
    newDate.getDate() +
    "/" +
    (newDate.getMonth() + 1) +
    "/" +
    newDate.getFullYear()
  );
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_STORAGE_KEY);
};
