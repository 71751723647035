import { Spin } from "antd";
import { useRef } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FROM_APP_IWS,
  FROM_APP_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
} from "src/constants/common";
import useConfig from "src/hooks/useConfig";

const FlashScreen = () => {
  // Get token from mobile
  let navigate = useNavigate();
  const { setCustomerId, setCurrentApp } = useConfig();
  let timeout = useRef();

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("REQUEST_TOKEN");
    }
    var iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    const getMessageFromApp = e => {
      if (e.data !== "BACK_COMMAND") {
        try {
          const { token, from_app, customer_id } = JSON.parse(e.data) || {};
          if (!token) {
            alert("Không tìm thấy thông tin! Vui lòng quay lại trang trước.");
            return;
          }
          localStorage.setItem(TOKEN_STORAGE_KEY, token);
          localStorage.setItem(FROM_APP_STORAGE_KEY, from_app);
          setCurrentApp(from_app);
          if (from_app === FROM_APP_IWS) {
            setCustomerId(customer_id);
          }
          timeout.current = setTimeout(() => {
            navigate("/point-events");
          }, 1000);
        } catch (error) {
          alert("Something went wrong");
        }
      }
    };
    // For iOS
    if (iOS) {
      window.addEventListener("message", getMessageFromApp, false);
    } else {
      document.addEventListener("message", getMessageFromApp, false);
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      if (iOS) {
        window.removeEventListener("message", getMessageFromApp);
      } else {
        document.removeEventListener("message", getMessageFromApp);
      }
    };
  });
  return (
    <div className="main-loading">
      <Spin />
    </div>
  );
};

export default FlashScreen;
