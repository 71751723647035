import { createContext, useState } from "react";
import PropTypes from "prop-types";
import { FROM_APP_IWS } from "src/constants/common";
export const ConfigContext = createContext();

function ConfigProvider({ children }) {
  const [currentApp, setCurrentApp] = useState();
  const [customerId, setCustomerId] = useState();

  return (
    <ConfigContext.Provider
      value={{
        currentApp,
        setCurrentApp,
        customerId,
        setCustomerId,
        iwsApp: currentApp === FROM_APP_IWS,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

ConfigProvider.propTypes = {
  children: PropTypes.node,
};

export default ConfigProvider;
