import axios from "axios";
import { FROM_APP_IWS, FROM_APP_STORAGE_KEY } from "src/constants/common";
import { getToken } from "src/utils/common";

const baseURL =
  localStorage.getItem(FROM_APP_STORAGE_KEY) === FROM_APP_IWS
    ? process.env.REACT_APP_IWS_API
    : process.env.REACT_APP_DEALER_API;

const axiosClient = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(
  function (config) {
    const token = getToken();
    if (token) {
      config.headers.common["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosClient;
