import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackIconClick = () => {
    const backToAppRouteList = ["/point-events"];
    if (backToAppRouteList.includes(location.pathname)) {
      document.removeEventListener("message", () => {}, true);
      if (window.ReactNativeWebView)
        window.ReactNativeWebView.postMessage("EXIT_COMMAND");
      return;
    }
    navigate(-1);
  };

  useEffect(() => {
    const backTo = e => {
      if (e.data === "BACK_COMMAND" && location.pathname === "/point-events") {
        document.removeEventListener("message", () => {});
        window.ReactNativeWebView.postMessage("EXIT_COMMAND");
        return;
      }

      navigate(-1);
    };

    document.addEventListener("message", backTo);
    return () => {
      document.removeEventListener("message", backTo);
    };
  }, [location.pathname]);

  return (
    <div className="header">
      <Button
        type="text"
        shape="circle"
        icon={<ArrowLeftOutlined className="header__icon" />}
        onClick={handleBackIconClick}
      />
      <span className="header__text">Điểm tích lũy</span>
    </div>
  );
}

Header.propTypes = {};

export default Header;
