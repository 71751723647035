import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ConfigProvider from "./contexts/ConfigContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FlashScreen from "./containers/FlashScreen";
import PointEvent from "./containers/PointEvent";
import PointEventDetail from "./containers/PointEventDetail";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider>
        <Router>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<FlashScreen />} />
              <Route exact path="point-events" element={<PointEvent />} />
              <Route
                exact
                path="point-events/:pointCode"
                element={<PointEventDetail />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </React.StrictMode>
);

function NotFound() {
  return <div>Không tìm thấy trang.</div>;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
