import { Table, Tabs } from "antd";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { formatDateToString } from "src/utils/common";

const TabPane = Tabs.TabPane;

function QuantityProduct({
  tabList,
  currentTab,
  tabContent,
  tabContentLoading,
  onTabChange,
}) {
  const totalPoint = useMemo(() => {
    return (
      tabContent?.reduce((prev, current) => {
        return prev + current?.current_point;
      }, 0) || 0
    );
  }, [tabContent]);
  const columns = [
    {
      title: "Tên sản phẩm",
      key: "name",
      render: row => (
        <div>
          <div>{row.product_sap_name}</div>
          <div>
            Mục tiêu số lượng:{" "}
            <b>
              {row.target} / {row.target_point} điểm
            </b>
          </div>
          <div>
            Số lượng đạt được: <b>{row.current}</b>
          </div>
        </div>
      ),
    },
    {
      title: "Điểm tích lũy đạt được",
      key: "point",
      dataIndex: "current_point",
      align: "center",
      width: 100,
    },
  ];

  return (
    <Tabs defaultActiveKey="1" onChange={onTabChange} className="tabs">
      {tabList?.map(tab => (
        <TabPane
          tab={<div className="tabs__item">{tab.name}</div>}
          key={tab.key}
        >
          <div className="info">
            <b>{currentTab?.name}</b>
            <div>
              Từ ngày {formatDateToString(currentTab?.start_at)} đến ngày{" "}
              {formatDateToString(currentTab?.end_at)}
            </div>
            <div>Tổng điểm: {totalPoint}</div>
          </div>

          <Table
            columns={columns}
            dataSource={tabContent}
            size="middle"
            pagination={false}
            loading={tabContentLoading}
          />
        </TabPane>
      ))}
    </Tabs>
  );
}

QuantityProduct.propTypes = {
  tabList: PropTypes.array,
  tabContent: PropTypes.array,
  onTabChange: PropTypes.func,
  currentTab: PropTypes.object,
  tabContentLoading: PropTypes.bool,
};

export default QuantityProduct;
